.logomark-animation {
	animation-name: logomark;
	animation-duration: 1000ms;
	animation-timing-function: ease-in-out;
	animation-delay: 100ms;
	animation-iteration-count: initial;
	animation-fill-mode: forwards;
}

.slide-left-animation {
	animation-name: slide-left;
	animation-duration: 850ms;
	animation-timing-function: ease-in-out;
	animation-delay: 0s;
	animation-iteration-count: initial;
	animation-fill-mode: forwards;
}

.fade-in-animation {
	opacity: 0;
	animation-name: fade-in;
	animation-duration: 500ms;
	animation-timing-function: ease-in;
	animation-delay: 850ms;
	animation-iteration-count: initial;
	animation-fill-mode: forwards;
}

@keyframes logomark {
	from {
		transform: rotate(-90deg);
	}

	to {
		transform: rotate(-360deg);
	}
}

@keyframes slide-left {
	0% {
		opacity: 0;
		transform: translate3d(120%, 0, 0);
	}

	20% {
		opacity: 1;
		transform: translate3d(120%, 0, 0);
	}

	100% {
		opacity: 1;
		transform: translate3d(0%, 0, 0);
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
